import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import SectionOne from "../Components/SectionOne";


const translateLanguage = {
  "Poker": "Poker",
"safe-avoid\r\n": "Éviter en toute sécurité",
"Quiz Game\r\n": "Jeu de Quiz",
"Pops Billiards\r\n": "Billard de Pops",
"Math Rush\r\n": "Course de Mathématiques",
  "Lucky Wheels\r\n": "Roues Chanceuses",
  "Jigsaw Puzzle\r\n": "Puzzle Jigsaw",
  "Ice Cream Memory\r\n": "Mémoire de Crème Glacée",
  "Hangman": "Pendu",
  "Nukkad Cricket": "Cricket de Nukkad",
  "Foodie match\r\n": "Match Gourmand",
  "Bubble\r\n": "Bulle",
  "Dark Village": "Village Sombre",
  "Ghost-Dropper": "Chute de Fantômes",
  "Piggy Roll": "Roulette de Cochon",
  "Rail Rush": "Ruée sur les Rails",
  "NeonPath": "Chemin Néon",
  "Terror-Shooter": "Tireur de Terreur",
  "Clash of battles": "Conflit des Batailles",
  "Rose Dash": "Dash de Rose",
  "Air Warfare": "Guerre Aérienne",
  "Helicopter Control": "Contrôle d'Hélicoptère",
  "Super Cowboy Run\r\n": "Course du Super Cowboy",
  "LittleDinoAdventureReturns": "Retour de l'Aventure du Petit Dino",
  "Chimney-Port\r\n": "Port de Cheminée",
  "christmas adventure\r\n": "Aventure de Noël",
  "SuDoKu": "SuDoKu",
  "Christmas Panda Run\r\n": "Course de Panda de Noël",
  "circle traffic\r\n": "Circulation Circulaire",
  "Tetris Cube\r\n": "Cube Tetris",
  "Dragon Jump": "Saut de Dragon",
  "Duck Shooter": "Tireur de Canard",
  "Flapcat Steampunk": "Chat Batteur Steampunk",
  "Shoot Robbers": "Tirer sur les Voleurs",
  "shooting gallery": "Galerie de Tir",
  "Gold Miner": "Chercheur d'Or",
  "Stick Panda": "Panda en Bâton",
  "Wood Slide": "Glissade en Bois",
  "BubbleBoostRush": "Rafale de Bulles",
  "Charly Bird": "Oiseau Charly",
  "Ninja UpDown": "Ninja Haut-Bas",
  "Reverse Gravity": "Gravité Inversée",
  "Santa Run": "Course du Père Noël",
  "Mad Scientist": "Scientifique Fou",
  "Happy haloween": "Joyeux Halloween",
  "Fruity snake": "Serpent Fruité",
  "Aliens Memory": "Mémoire des Aliens",
  "Panic Drop": "Chute Panique",
  "ranger vs zombies": "Ranger contre Zombies",
  "Road Racer": "Coureur de Route",
  "Robber Run": "Course du Voleur",
  "Scary Run": "Course Effrayante",
  "Crazy Car": "Voiture Folle",
  "Fighter Aircraft": "Avion de Chasse",
  "Goof Runner": "Coureur Bizarre",
  "Great Air Battles": "Grandes Batailles Aériennes",
  "Animal Crush": "Écrasement Animalier",
  "Ninja Run": "Course de Ninja",
  "samup": "Samup",
  "Extreme Sea Winner": "Vainqueur Extrême de la Mer",
  "Moto Traffic Rider": "Pilote de Moto en Circulation",
  "Traffic command": "Commandement du Trafic",
  "Math Pop": "Pop Mathématique",
  "Puzzle for kids": "Puzzle pour Enfants",
  "Find a Pair Animals": "Trouver une Paire d'Animaux",
  "Chess Tower": "Tour d'Échecs",
  "Dices jump": "Saut de Dés",
  "Bubble Town": "Ville des Bulles",
  "Escape": "Évasion",
  "Pinball Rush": "Ruée de Flipper",
  "Free Kick Penalty": "Coup Franc et Pénalty",
  "Penalty Shootout": "Tirs au But",
  "Boom Baseball": "Baseball Boom",
  "Blocker": "Bloqueur",
  "Coloring Book For Kids": "Livre de Coloriage pour Enfants",
  "Cat and Ghosts": "Chat et Fantômes",
  "Pool 8 Ball": "Boule de Billard 8",
  "Gold miner": "Chercheur d'Or",
  "Stack Jump": "Saut de Pile",
  "Handless Millionaire": "Millionnaire sans Mains",
  "Angry cat school": "École du Chat Furieux"
};

const SeeAll = () => {

  //Getting On Page Load
  useEffect(() => {
    getLocalStorageData();
  }, []);

  //State to Store Selected Game Data
  const [data, setData] = useState([]);
 console.log("=",data)
  //Getting Data From Local Storage
  const getLocalStorageData = () => {
    let games = JSON.parse(localStorage.getItem("games"));
    // console.log("games ", games);
    setData(games);
  };

  return (
    <>
      <Header />
      <SectionOne />
      <div className="area-bg-one ">
        <section className="pt-120 bg-black/20">
          <div className="container ">
            <div className="row">
   
              {data.map((value, index) => {
                return (
                  <div className="col-lg-4 col-md-6" key={index}>
                    <div className="upcoming-game-item mb-40">
                      <div className="upcoming-game-head">
                        <div className="uc-game-head-title">
                          <h4>
                            <span onClick={()=>{
                              window.location.href=value.game;
                            }} style={{cursor:"pointer"}}>
                             {translateLanguage[value.name] || value.name}</span>
                          </h4>
                        </div>
                        <div className="uc-game-price">
                          <h5>&#9971;</h5>
                        </div>
                      </div>
                      <p>
                      Affrontez des joueurs sur une île éloignée, le gagnant remporte le duel problème connu.
                      </p>
                      <div className="upcoming-game-thumb">
                        <img src={value.image} alt="Src" className="h-[200px] object-cover" />
                        <div className="upcoming-game-cart">
                          <a href={value.game} className="btn transparent-btn">
                            <i className="fas fa-shopping-basket"></i> Jouer
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};
export default SeeAll;
