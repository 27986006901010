import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import SectionOne from "../Components/SectionOne";
import SlickSliderTop from "../Components/SlickSliderTop";
import SlickSliderBottom from "../Components/SlickSliderBottom";
import OwlSlider from "../Components/OwlSlider";
import SectionTwo from "../Components/SectionTwo";
import SectionThree from "../Components/SectionThree";
import Footer from "../Components/Footer";
import Preloader from "../Components/Preloader";
import GetRequest from "../API/GetRequest";
import { getGamesApi } from "../Data/data";
import "../CSS/new.css";
import axios from "axios";
import Cookies from 'js-cookie';

const Home = () => {

  const navigate = useNavigate();

  const [response, setResponse] = useState(null);
  const msisdn = Cookies.get("msisdn");

  // const checkUser = () => {
  //   axios.get(`http://91.205.172.123:5631/checkuser/${msisdn}`)
  //     .then((res) => {
  //       console.log("res==========", res.data.status);
  //       if (res.data.status === 0) {
  //         navigate('/login'); 
  //       } else {
  //         setResponse(res.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //       setResponse('Error occurred while fetching data.');
  //     });
  // };

  useEffect(() => {
    // checkUser();
    getGamesFromBackend();
    // eslint-disable-next-line
  }, []);

  const getGamesFromBackend = () => {
    const promise = GetRequest(getGamesApi);
    promise.then(e => {
      console.log("data ", e.response);

      if (e.message === 'Network Error') {
        navigate("/error");
      }

      processGamesData(e.response);
      gettingThreeGames(e.response);
    });
  };

  const [data, setData] = useState([]);

  const processGamesData = (games) => {
    setData(games);
    setLoaderState(false);
  };

  let count = 0;

  const [threeGamesArray, setThreeGamesArray] = useState([]);

  const gettingThreeGames = (games) => {
    games.forEach(e => {
      if (e.category === 'mostPlayed') {
        let arr = e.game.splice(0, 3);
        setThreeGamesArray(arr);
      }
    });
  };

  const [loaderState, setLoaderState] = useState(true);

  return (
    <>
      {loaderState && (
        <div aria-label="Loading..." role="status" className="flex items-center justify-center min-h-screen">
          <div className="flex items-center space-x-2">
            <svg className="h-20 w-20 animate-spin stroke-gray-500" viewBox="0 0 256 256">
              <line x1="128" y1="32" x2="128" y2="64" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
              <line x1="195.9" y1="60.1" x2="173.3" y2="82.7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
              <line x1="224" y1="128" x2="192" y2="128" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
              <line x1="195.9" y1="195.9" x2="173.3" y2="173.3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
              <line x1="128" y1="224" x2="128" y2="192" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
              <line x1="60.1" y1="195.9" x2="82.7" y2="173.3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
              <line x1="32" y1="128" x2="64" y2="128" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
              <line x1="60.1" y1="60.1" x2="82.7" y2="82.7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
            </svg>
            <span className="text-4xl font-medium text-gray-500">Loading...</span>
          </div>
        </div>
      )}

      {!loaderState && (
        <>
          <Preloader />
          <Header data={data} />
          <main>
            <SectionOne data={data} />
            {/* <SlickSliderTop /> */}
            <div className="area-bg-one">
              {data.map((e, key) => {
                count = count + 1;
                if (e.category === 'mostPlayed')
                  return <span key={key}></span>;
                else
                  return (
                    <div key={key}>
                      <OwlSlider
                        list={e.game}
                        data={data}
                        id={e.category}
                        name={e.category}
                      />
                      {count === 2 ? <SectionTwo key={count} data={threeGamesArray} /> : <></>}
                      {count === 4 ? <SectionThree list={data} key={count} /> : <></>}
                      {count === 8 ? <SlickSliderBottom key={count} /> : <></>}
                    </div>
                  );
              })}
            </div>
          </main>
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
