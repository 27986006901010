import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import janguKids from "./logoooo.png";

const Header = ({ data }) => {
  const navigate = useNavigate();
  const [searchList, setSearchList] = useState([]);
  const [activeCategory, setActiveCategory] = useState(""); // Track the active category
  const [menuOpen, setMenuOpen] = useState(false); // Manage the hamburger menu visibility

  const handleSearch = (search) => {
    let arr = [];
    data.data.forEach((e) => {
      e.game.forEach((f) => {
        if (
          search !== "" &&
          search !== " " &&
          (f.name.toLowerCase().startsWith(search) || f.name.startsWith(search))
        ) {
          arr.push(f.name);
        }
      });
    });
    setSearchList(arr);
  };

  const handleSearchPlay = (name) => {
    data.data.forEach((e) => {
      e.game.forEach((f) => {
        if (f.name === name) {
          window.location.href = f.game;
        }
      });
    });
  };

  return (
    <header className="fixed inset-x-0 top-0 z-30 mx-auto w-full max-w-screen-md bg-white/80 py-3 shadow backdrop-blur-lg md:top-6 md:rounded-3xl lg:max-w-screen-lg">
      <div className="px-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
            >
              <img className="h-9 w-auto" src={janguKids} alt="Logo" />
            </span>
          </div>
          {/* Hamburger Menu for Mobile View */}
          <div className="md:hidden">
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="text-gray-900 focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
          {/* Navigation Links and Search Button for Desktop */}
          <div className=" ml-4 hidden md:flex md:items-center md:justify-between w-full">
            <div className="flex items-center gap-5 font-bold">
              {[
    "Sports",
    "Drôle",
    "Action",
    "Aventure",
    "Course",
    "Enfants",
    "Arcade",
    "Puzzle"
]
.map((category) => (
                <a
                  key={category}
                  href={`#${category}`}
                  className={`inline-block rounded-lg px-2 py-1 text-sm font-medium transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 ${
                    activeCategory === category
                      ? "bg-yellow-500 text-white"
                      : "text-gray-900"
                  }`}
                  onClick={() => {
                    setActiveCategory(category);
                  }}
                >
                  {category}
                </a>
              ))}
            </div>
            <div className="flex items-center justify-end gap-3">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-xl bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 transition-all duration-150 hover:bg-gray-50"
                data-toggle="modal"
                data-target="#search-modal"
              >
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        {menuOpen && (
          <div className="mt-2 flex flex-col space-y-2 md:hidden">
            {[
    "Sports",
    "Drôle",
    "Action",
    "Aventure",
    "Course",
    "Enfants",
    "Arcade",
    "Puzzle"
]
.map((category) => (
              <a
                key={category}
                href={`#${category}`}
                className={`block rounded-lg px-3 py-2 text-base font-medium transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 ${
                  activeCategory === category
                    ? "bg-yellow-500 text-white"
                    : "text-gray-900"
                }`}
                onClick={() => {
                  setActiveCategory(category);
                  setMenuOpen(false); // Close menu after selecting a category
                }}
              >
                {category}
              </a>
            ))}
          </div>
        )}
      </div>

      {/* Search Modal */}
      <div
        className="modal fade"
        id="search-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form>
              <input
                type="text"
                placeholder="Search here..."
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className="w-full border-0 focus:ring-0"
              />
              <button type="button" className="hidden">
                <i className="fa fa-search"></i>
              </button>
              <div className="search-main-div">
                {searchList.length > 0 &&
                  searchList.map((val, key) => (
                    <div key={key} className="search-div">
                      <span
                        className="search-span cursor-pointer"
                        onClick={() => {
                          handleSearchPlay(val);
                        }}
                      >
                        {val}
                      </span>
                    </div>
                  ))}
              </div>
            </form>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
