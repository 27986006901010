import React from "react";
import OwlCarousel from "react-owl-carousel";
import { useNavigate } from "react-router-dom";
const translateLanguage = {
  "Poker": "Poker",
"safe-avoid\r\n": "Éviter en toute sécurité",
"Quiz Game\r\n": "Jeu de Quiz",
"Pops Billiards\r\n": "Billard de Pops",
"Math Rush\r\n": "Course de Mathématiques",
  "Lucky Wheels\r\n": "Roues Chanceuses",
  "Jigsaw Puzzle\r\n": "Puzzle Jigsaw",
  "Ice Cream Memory\r\n": "Mémoire de Crème Glacée",
  "Hangman": "Pendu",
  "Nukkad Cricket": "Cricket de Nukkad",
  "Foodie match\r\n": "Match Gourmand",
  "Bubble\r\n": "Bulle",
  "Dark Village": "Village Sombre",
  "Ghost-Dropper": "Chute de Fantômes",
  "Piggy Roll": "Roulette de Cochon",
  "Rail Rush": "Ruée sur les Rails",
  "NeonPath": "Chemin Néon",
  "Terror-Shooter": "Tireur de Terreur",
  "Clash of battles": "Conflit des Batailles",
  "Rose Dash": "Dash de Rose",
  "Air Warfare": "Guerre Aérienne",
  "Helicopter Control": "Contrôle d'Hélicoptère",
  "Super Cowboy Run\r\n": "Course du Super Cowboy",
  "LittleDinoAdventureReturns": "Retour de l'Aventure du Petit Dino",
  "Chimney-Port\r\n": "Port de Cheminée",
  "christmas adventure\r\n": "Aventure de Noël",
  "SuDoKu": "SuDoKu",
  "Christmas Panda Run\r\n": "Course de Panda de Noël",
  "circle traffic\r\n": "Circulation Circulaire",
  "Tetris Cube\r\n": "Cube Tetris",
  "Dragon Jump": "Saut de Dragon",
  "Duck Shooter": "Tireur de Canard",
  "Flapcat Steampunk": "Chat Batteur Steampunk",
  "Shoot Robbers": "Tirer sur les Voleurs",
  "shooting gallery": "Galerie de Tir",
  "Gold Miner": "Chercheur d'Or",
  "Stick Panda": "Panda en Bâton",
  "Wood Slide": "Glissade en Bois",
  "BubbleBoostRush": "Rafale de Bulles",
  "Charly Bird": "Oiseau Charly",
  "Ninja UpDown": "Ninja Haut-Bas",
  "Reverse Gravity": "Gravité Inversée",
  "Santa Run": "Course du Père Noël",
  "Mad Scientist": "Scientifique Fou",
  "Happy haloween": "Joyeux Halloween",
  "Fruity snake": "Serpent Fruité",
  "Aliens Memory": "Mémoire des Aliens",
  "Panic Drop": "Chute Panique",
  "ranger vs zombies": "Ranger contre Zombies",
  "Road Racer": "Coureur de Route",
  "Robber Run": "Course du Voleur",
  "Scary Run": "Course Effrayante",
  "Crazy Car": "Voiture Folle",
  "Fighter Aircraft": "Avion de Chasse",
  "Goof Runner": "Coureur Bizarre",
  "Great Air Battles": "Grandes Batailles Aériennes",
  "Animal Crush": "Écrasement Animalier",
  "Ninja Run": "Course de Ninja",
  "samup": "Samup",
  "Extreme Sea Winner": "Vainqueur Extrême de la Mer",
  "Moto Traffic Rider": "Pilote de Moto en Circulation",
  "Traffic command": "Commandement du Trafic",
  "Math Pop": "Pop Mathématique",
  "Puzzle for kids": "Puzzle pour Enfants",
  "Find a Pair Animals": "Trouver une Paire d'Animaux",
  "Chess Tower": "Tour d'Échecs",
  "Dices jump": "Saut de Dés",
  "Bubble Town": "Ville des Bulles",
  "Escape": "Évasion",
  "Pinball Rush": "Ruée de Flipper",
  "Free Kick Penalty": "Coup Franc et Pénalty",
  "Penalty Shootout": "Tirs au But",
  "Boom Baseball": "Baseball Boom",
  "Blocker": "Bloqueur",
  "Coloring Book For Kids": "Livre de Coloriage pour Enfants",
  "Cat and Ghosts": "Chat et Fantômes",
  "Pool 8 Ball": "Boule de Billard 8",
  "Gold miner": "Chercheur d'Or",
  "Stack Jump": "Saut de Pile",
  "Handless Millionaire": "Millionnaire sans Mains",
  "Angry cat school": "École du Chat Furieux",
  "Adventure":"Aventure",
  "Puzzle":"Casse-tête",
  "Funny":"Drôle",
  "Kids":"Enfants",
  "Racing":" Course",
  "Sports":"Sports",
  "Arcade":"Arcade",
};






const OwlSlider = (props) => {
  const navigate = useNavigate();

  const options = {
    items: 3,
    margin: 30,
    nav: true,
    loop: true,
    navText: [
      '<i class="arrow fa fa-angle-left"></i>',
      '<i class="arrow fa fa-angle-right"></i>',
    ],
    responsiveClass: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1.5,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const seeAllButton = (category) => {
    gettingRequiredData(props.data, category);
  };

  const gettingRequiredData = (data, category) => {
    data.forEach((e) => {
      if (category === e.category) {
        localStorage.setItem("games", JSON.stringify(e.game));
      }
    });
    navigate("/seeAll");
  };

  props.list.forEach((e) => {
    console.log(e.name);
  });

  return (
    <section className="latest-games-area pt-120" id={props.id}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-8">
            <div className="section-title mb-25">
              <h2>
                <span>{translateLanguage [props.name]||props.name}</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <OwlCarousel
              {...options}
              className="latest-games-active owl-carousel owl-theme"
            >
              {props.list.map((e, key) => {
                return (
                  <div
                    className="group relative overflow-hidden shadow-lg max-w-sx mb-30"
                    key={key}
                  >
                    <a
                      href={e.game}
                      className="absolute z-10 top-0 bottom-0 left-0 right-0"
                    ></a>
                    <img
                      src={e.image}
                      alt="Game"
                      className="block w-full h-[300px]  object-cover group-hover:opacity-40 transition-opacity duration-700"
                    />
                    <div
                      className="absolute bg-black flex items-center group-hover:-top-0 group-hover:opacity-100 duration-700 top-full right-0 w-full opacity-0 h-1/2 transition-all"
                      style={{ backgroundImage: `url(${e.image})` }}
                    ></div>
                    <div className="absolute bg-gradient-to-br from-green-800 to-blue-800 text-white block left-0 right-0 top-full text-base h-1/2 w-full opacity-50 transition-all group-hover:top-1/2 group-hover:opacity-100">
                      <div className="py-4 text-xs px-7">
                        <div className="text-xl font-bold">
                        {translateLanguage[e.name] || e.name}
                        </div>
                      </div>
                      <div className="absolute left-0 pl-7 pt-1">
                        <div class="flex h-12 justify-center gap-x-6 dark:text-white">
                          <a
                            class="group flex h-min ring-none items-center justify-center hover:opacity-95 disabled:opacity-50 rounded-lg py-2 px-4 font-dm focus:outline-none !ring-transparent text-violet-800 border border-violet-500 border-b-violet-400 border-b-4 hover:border active:border bg-white hover:text-violet-900 hover:bg-gray-50 active:bg-gray-100 active:text-violet-600 focus-visible:outline-violet-600 focus-visible:ring-violet-700 text-sm sm:text-base dark:bg-gray-700 dark:border-gray-700 dark:border-b-gray-900 dark:text-white"
                            href=""
                          >
                            <svg
                              aria-hidden="true"
                              class="h-3 w-3 flex-none fill-violet-600 group-active:fill-current"
                            >
                              <path d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z"></path>
                            </svg>
                            <span class="ml-3 text-black">
                              Jouer maintenant
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                 
                  </div>
                );
              })}
            </OwlCarousel>
            <div className="see-all">
              <div
                href=""
                class="flex items-center text-indigo-700 border border-indigo-600 py-2 px-6 gap-2 rounded inline-flex cursor-pointer "
              >
                <span
                  onClick={() => {
                    seeAllButton(props.name);
                  }}
                >
                 Voir Tout
                </span>
                <svg
                  class="w-4"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  classname="w-6 h-6 ml-2"
                >
                  <path d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OwlSlider;
