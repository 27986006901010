import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import game_overview_thumb from "../Media/Images/images/game_overview_thumb.png";

const SectionThree = (list) => {
  const navigate = useNavigate();

  const handleMostPlayedButton = () => {
    list.list.forEach((e) => {
      if (e.category === 'mostPlayed') {
        localStorage.setItem("games", JSON.stringify(e.game));
        navigate("/seeAll");
      }
    });
  };

  return (
    <motion.div 
      className="breadcrumb-area breadcrumb-bg game-overview-breadcrumb"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <motion.div 
            className="col-xl-4 col-lg-6"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <div className="game-overview-img">
              <img
                src={game_overview_thumb}
                alt=""
              />
            </div>
          </motion.div>
          <motion.div 
            className="col-xl-8 col-lg-6"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <div className="breadcrumb-content text-center text-lg-left pl-80">
              <h2>
              Jeux les Plus Joués <span>{new Date().getFullYear()}</span>
              </h2>
              <div className="game-overview-status">
                <ul>
                  <li></li>
                  <li></li>
                </ul>
              </div>
              <motion.span
                onClick={handleMostPlayedButton}
                className="btn btn-style-two"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Essayez les Plus Joués...
              </motion.span>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default SectionThree;
