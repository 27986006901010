import React from "react";
import { useNavigate } from "react-router-dom";
import img from './headerimg.jpg';

const SectionOne = (data) => {
  const navigate = useNavigate();

  const handlePlayGames = () => {
    data.data.forEach((e) => {
      if (e.category === "mostPlayed") {
        localStorage.setItem("games", JSON.stringify(e.game));
        navigate("/seeAll");
      }
    });
  };

  return (
    <section
      className="slider-area bg-cover bg-center py-16 min-h-[500px] flex items-center justify-center"
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="bg-opacity-60 bg-black p-6 rounded-lg max-w-md w-full mx-4 md:max-w-lg lg:max-w-xl">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-white mb-4 md:text-4xl lg:text-5xl">
            Portail<span className="text-yellow-300"> de Jeux </span>
          </h2>
          <p className="text-white text-base mb-6 md:text-lg">
            TROUVEZ DE NOUVEAUX JEUX BASÉS SUR DE NOUVELLES TECHNOLOGIES, 
            PLEINS DE FUN, DE DIVERTISSEMENT ET DE CONNAISSANCES.
          </p>
          <button
            className="bg-yellow-300 text-purple-800 font-bold py-2 px-4 rounded-full transition-transform transform hover:scale-105 focus:outline-none"
            onClick={handlePlayGames}
          >
            Jouer aux Jeux
          </button>
        </div>
      </div>
    </section>
  );
};

export default SectionOne;
