import React from "react";
// import gamer_list_icon1 from "../Media/Images/icon/gamer_list_icon01.png";
// import gamer_list_icon2 from "../Media/Images/icon/gamer_list_icon02.png";
// import gamer_list_icon3 from "../Media/Images/icon/gamer_list_icon03.png";
import just_gamers_img from "../Media/Images/images/just_gamers_img.png";
import gamers_circle_line from "../Media/Images/images/gamers_circle_line.png";
import gamers_circle_shape from "../Media/Images/images/gamers_circle_shape.png";
import just_gamers_chart from "../Media/Images/images/just_gamers_chart.png";
import "../CSS/new.css";


const translateLanguage = {
  "Poker": "Poker",
"safe-avoid\r\n": "Éviter en toute sécurité",
"Quiz Game\r\n": "Jeu de Quiz",
"Pops Billiards\r\n": "Billard de Pops",
"Math Rush\r\n": "Course de Mathématiques",
  "Lucky Wheels\r\n": "Roues Chanceuses",
  "Jigsaw Puzzle\r\n": "Puzzle Jigsaw",
  "Ice Cream Memory\r\n": "Mémoire de Crème Glacée",
  "Hangman": "Pendu",
  "Nukkad Cricket": "Cricket de Nukkad",
  "Foodie match\r\n": "Match Gourmand",
  "Bubble\r\n": "Bulle",
  "Dark Village": "Village Sombre",
  "Ghost-Dropper": "Chute de Fantômes",
  "Piggy Roll": "Roulette de Cochon",
  "Rail Rush": "Ruée sur les Rails",
  "NeonPath": "Chemin Néon",
  "Terror-Shooter": "Tireur de Terreur",
  "Clash of battles": "Conflit des Batailles",
  "Rose Dash": "Dash de Rose",
  "Air Warfare": "Guerre Aérienne",
  "Helicopter Control": "Contrôle d'Hélicoptère",
  "Super Cowboy Run\r\n": "Course du Super Cowboy",
  "LittleDinoAdventureReturns": "Retour de l'Aventure du Petit Dino",
  "Chimney-Port\r\n": "Port de Cheminée",
  "christmas adventure\r\n": "Aventure de Noël",
  "SuDoKu": "SuDoKu",
  "Christmas Panda Run\r\n": "Course de Panda de Noël",
  "circle traffic\r\n": "Circulation Circulaire",
  "Tetris Cube\r\n": "Cube Tetris",
  "Dragon Jump": "Saut de Dragon",
  "Duck Shooter": "Tireur de Canard",
  "Flapcat Steampunk": "Chat Batteur Steampunk",
  "Shoot Robbers": "Tirer sur les Voleurs",
  "shooting gallery": "Galerie de Tir",
  "Gold Miner": "Chercheur d'Or",
  "Stick Panda": "Panda en Bâton",
  "Wood Slide": "Glissade en Bois",
  "BubbleBoostRush": "Rafale de Bulles",
  "Charly Bird": "Oiseau Charly",
  "Ninja UpDown": "Ninja Haut-Bas",
  "Reverse Gravity": "Gravité Inversée",
  "Santa Run": "Course du Père Noël",
  "Mad Scientist": "Scientifique Fou",
  "Happy haloween": "Joyeux Halloween",
  "Fruity snake": "Serpent Fruité",
  "Aliens Memory": "Mémoire des Aliens",
  "Panic Drop": "Chute Panique",
  "ranger vs zombies": "Ranger contre Zombies",
  "Road Racer": "Coureur de Route",
  "Robber Run": "Course du Voleur",
  "Scary Run": "Course Effrayante",
  "Crazy Car": "Voiture Folle",
  "Fighter Aircraft": "Avion de Chasse",
  "Goof Runner": "Coureur Bizarre",
  "Great Air Battles": "Grandes Batailles Aériennes",
  "Animal Crush": "Écrasement Animalier",
  "Ninja Run": "Course de Ninja",
  "samup": "Samup",
  "Extreme Sea Winner": "Vainqueur Extrême de la Mer",
  "Moto Traffic Rider": "Pilote de Moto en Circulation",
  "Traffic command": "Commandement du Trafic",
  "Math Pop": "Pop Mathématique",
  "Puzzle for kids": "Puzzle pour Enfants",
  "Find a Pair Animals": "Trouver une Paire d'Animaux",
  "Chess Tower": "Tour d'Échecs",
  "Dices jump": "Saut de Dés",
  "Bubble Town": "Ville des Bulles",
  "Escape": "Évasion",
  "Pinball Rush": "Ruée de Flipper",
  "Free Kick Penalty": "Coup Franc et Pénalty",
  "Penalty Shootout": "Tirs au But",
  "Boom Baseball": "Baseball Boom",
  "Blocker": "Bloqueur",
  "Coloring Book For Kids": "Livre de Coloriage pour Enfants",
  "Cat and Ghosts": "Chat et Fantômes",
  "Pool 8 Ball": "Boule de Billard 8",
  "Gold miner": "Chercheur d'Or",
  "Stack Jump": "Saut de Pile",
  "Handless Millionaire": "Millionnaire sans Mains",
  "Angry cat school": "École du Chat Furieux"
};
const SectionTwo = (data) => {
  return (
    <section className="just-gamers-area just-gamers-bg pt-115 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-md-10">
            <div className="section-title title-style-three white-title mb-70">
              <h2>
              TOP TROIS <span>JEUX</span>
              </h2>
              {/* <p>
                Till now 100k+ people play and enjoy these games. Playing games
                can contribute to making you happy, perhaps even without
                therapy.
              </p> */}
            </div>
            <div className="just-gamers-list">
              <ul>
                {data.data.map((value, key) => {
                  return (
                    <li key={key}>
                      <div
                        style={{cursor:"pointer"}}
                        className="just-gamers-list-icon"
                        onClick={() => {
                         window.location.href=`${value.game}`;
                        }}
                      >
                        <img
                          src={value.image}
                          alt="ImgSrc"
                          height="80px"
                          width="80px"
                        />
                      </div>
                      <div
                        className="just-gamers-list-content fix"
                        style={{cursor:"pointer"}}
                        onClick={() => {
                          window.location.href=`${value.game}`;
                        }}
                      >
                        <h5 className="prevent-select">{translateLanguage[value.name]||value.name.toUpperCase()}</h5>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6 d-none d-lg-block">
            <div className="just-gamers-img">
              <img
                src={just_gamers_img}
                //   src="img/images/just_gamers_img.png"
                alt=""
                className="just-gamers-character"
              />
              <div className="just-gamers-circle-shape">
                <img
                  src={gamers_circle_line}
                  //   src="img/images/gamers_circle_line.png"
                  alt=""
                />
                <img
                  src={gamers_circle_shape}
                  // src="img/images/gamers_circle_shape.png"
                  alt=""
                  className="rotateme"
                />
              </div>
              <img
                src={just_gamers_chart}
                // src="img/images/just_gamers_chart.png"
                alt=""
                className="gamers-chart-shape"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SectionTwo;