import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import Home from "../Pages/Home";
import PageNotFound from "../Pages/PageNotFound";
import SeeAll from "../Pages/SeeAll";
import ErrorPage from "../Pages/Error";
import { v4 as uuidv4 } from 'uuid';
import Login from '../Pages/Login';

const RedirectToExternal = ({ url }) => {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};

const Routing = () => {
let currenturl=window.location.href
let url=new URL(currenturl)
let params= new URLSearchParams(url.search)
console.log("paramss",params)
let ext_ref=params.get("ext_ref")
console.log("ext_ref",ext_ref)
  const uniqueId = uuidv4();

  return (
    <BrowserRouter>
    
      <Routes>
        <Route path="/" exact element={<Home />} />
        {/* <Route path ='/login' exact element={<Login/>}/> */}
        <Route path="/seeAll" exact element={<SeeAll />} />
        <Route path="/daily" exact element={<RedirectToExternal url={`http://ng-app.com/H2nCommunications/Jangukids-24-No-23410220000027476-web?trxId=${ext_ref}`} />} />

        <Route path="/weekly" exact element={<RedirectToExternal url={`http://ng-app.com/H2nCommunications/Jangukids-168-No-23410220000027477-web?trxId=${ext_ref}`} />} />

        <Route path="/monthly" exact element={<RedirectToExternal url={`http://ng-app.com/H2nCommunications/Jangukids-720-No-23410220000027478-web?trxId=${ext_ref}`} />} />


        <Route path="*" exact element={<PageNotFound />} />
        <Route path="/error" exact element={<ErrorPage />} />

  
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
