// const pre=`http://localhost:2929/`;
// const pre=`http://5.189.166.187:5551/GamePortalBackendNewLiveDemo/`;
// const pre=`http://5.189.146.57:9049/GamePortalBackendNewLiveDemo/`;
// const pre=  `https://backendapi.uvcanngaming.com/`;
// const pre=`http://176.9.90.155:8123/UvGamePortalBackend/`
const pre=`https://backenduvcan.gameit.in/`
const getGamesApi=`${pre}sendGames`;
export{getGamesApi};

// http://5.189.166.187:5551/GamePortalBackendNewLiveDemo/sendGames

// http://localhost:
